import Model3DViewer from './3d-viewer/Model3DViewer.js';
import Model3DViewerContainer from './3d-viewer/Model3DViewerContainer.js';

window.ProductDesignView = {};

window.ProductDesignView.initUiTasks = function(selector){
	//Image gallery 3D preview
	$(selector).find('.image-gallery-3dmodel').each(function() {
		var me = this;

		//Get product object
		var product = Products.getProduct($(this).attr('data-product-id'));

		//Init model viewer
		var modelViewer = new Model3DViewer(this, $(me).width(), 500/560*$(me).width());
		modelViewer.init(product, $(this).attr('data-product-slug'), $(this).attr('data-product-variant'));
		if(modelViewer.nosupport){
			window.ProductDesignView.set3Dviewer.call(this, null, product);
		} else {
			window.ProductDesignView.set3Dviewer.call(this, modelViewer, product);
		}
		
		window.aow.modelViewer = modelViewer;
	});

	$(selector).find('.image-gallery-3dmodelcontainer').each(function() {
		var me = this;
		var product = Products.getProduct($(this).attr('data-product-id'));
		var textures = $(this).attr('data-textures').split(';');
		var children = product.getChildren();

		var viewers = [];
		for(var i = 0; i < textures.length; i++){
			for(var j = 0; j < children.length; j++){
				var [textureSlug, textureUrl] = textures[i].split('|');
				// Check if texture and children correspond
				var productSlug = children[j].static.slug.split(product.static.slug + '-')[1];
				if(textureSlug.split('-texture')[0] != productSlug) continue;
				// Add viewer info
				viewers.push({
					'texture': textureUrl,
					'product': product,
					'child_product': Products.getProduct(children[j].static.id),
					'model_name': children[j].static.slug,
					'variant': 'none'
				});
			}
		}

		if(window.aow.modelViewerContainer){
			window.aow.modelViewerContainer.deleteSelf();
		}

		var modelViewerContainer = new Model3DViewerContainer(this, $(me).width(), 500/560*$(me).width(), viewers,
			function(){
				$('#productInfo-productOption-size').trigger('change');
				$('#productInfo-productOption-product-style').trigger('change');
			}
		);

		window.aow.modelViewerContainer = modelViewerContainer;

		// Maybe it's dead
		if(!modelViewerContainer.modelViewers.length){
			window.ProductDesignView.set3Dviewer.call(this, null, product);
		} else {
			for(i = 0; i < modelViewerContainer.modelViewers.length; i++){
				$(this).attr('data-texture', viewers[i]['texture']);
				window.ProductDesignView.set3Dviewer.call(this, modelViewerContainer.modelViewers[i], product);
			}
		}
	});
};

window.ProductDesignView.set3Dviewer = function(modelViewer, product){
	var container = this;

	// Reset the thumb index if support is ok
	if(modelViewer && !modelViewer.nosupport){
		window.aow.selectedThumb = 0;
	}

	var viewers = [], i;

	// If it's an art print
	if(product.static.saveId == 37){
		$('#productInfo-productOption-size').find('option').last().prop('selected', true);
		var modelLoading = true;
		var savedCameraPos = null;

		if(modelViewer) {
			modelViewer.onObjectLoad = function(){
				//Add print size change event
				$('#productInfo-productOption-size').off('change.model');
				$('#productInfo-productOption-size').on('change.model', function() {
					var value = $(this).find(':selected').attr('data-slug');

					//Stop here if we don't have a value
					if(!value)
						return;

					var width = value.split('x')[0].replace('_', '.');
					var height = value.split('x')[1].replace('_', '.');

					// Resize object							
					var models = modelViewer.object.children, i = models.length;
					while(i--){
						var scale = product.getScale(value);
						if(scale){
							models[i].scale.x = scale;
							models[i].scale.y = scale;
							models[i].scale.z = scale;
						} else {
							if(product.static.id == 39){
								//models[i].scale.x = width / 22;
								//models[i].scale.y = width / 22;
								//models[i].scale.z = width / 22;
							} else if(product.static.id == 76) {
								models[i].scale.x = width / 221;
								models[i].scale.y = width / 221;
								models[i].scale.z = width / 221;
							} else { 
								//models[i].scale.x = width / 26;
								//models[i].scale.y = width / 26;
								//models[i].scale.z = width / 26;
							}
						}
					}

					$('.image-thumb-list').find('.image-thumb').first().click();

					if(product.static.id == 38){
						$('#productInfo-productOption-border-size').trigger('change');
					}
					if(product.static.id == 40){
						$('#productInfo-productOption-thickness').trigger('change');
					}
				});

				$('#productInfo-productOption-size').trigger('change');

				// If it's an art print
				if(product.static.id == 38){
					//Add border size change event
					$('#productInfo-productOption-border-size').on('change', function() {
						// Resize texture
						var selectedSize = $('#productInfo-productOption-size').find(':selected').attr('data-slug');

						if(!selectedSize){
							return;
						}

						var width = selectedSize.split('x')[0].replace('_', '.'), height = selectedSize.split('x')[1].replace('_', '.');
						var value = $(this).find(':selected').attr('data-slug');
						product.addBorders(parseFloat(value.slice(0, -5).replace('-', '.')), modelViewer, width, height);
					});
					$('#productInfo-productOption-border-size').trigger('change');
				}

				modelLoading = false;
			};
		}
	} else if(product.static.id == 14 || product.static.id == 13) {
		$('#productInfo-productOption-size').on('change', function(){
			var value = $(this).find(':selected').attr('data-slug');
			var flat = 'flat';
			/*if($('#productInfo-productOption-fabric').find(':selected').attr('data-slug') != 'polychiffon'){
				value += '-silk';
				flat += '-silk';
				if(modelViewer && !modelViewer.lut){
					modelViewer.enableLut('textures/lut_silk.png');
					modelViewer.lut.addWarning($('#productInfo-productOption-fabric'));
					$('#productInfo-productOption-fabric').material_select();
				}
			} else {
				if(modelViewer.lut){
					modelViewer.lut.removeWarning();
				}
				modelViewer.disableLut();
			}*/
			var activeSlug = $('.image-thumb-list').find('.image-thumb-active').data('slug');
			if(activeSlug != 'texture'){
				//$('.image-thumb-list').find('.image-thumb[data-slug=' + (activeSlug == 'flat' || activeSlug == 'flat-silk' ? flat : value) + ']').click();
				$('.image-thumb-list').find('.image-thumb[data-slug=' + (activeSlug == 'flat' ? flat : value) + ']').click();
			}
			$('.image-thumb-list').find('.image-thumb').each(function(){
				if(['texture', value, flat].indexOf($(this).attr('data-slug')) != -1){
					$(this).show();
				} else {
					$(this).hide();
				}
			});
		});
		/*$('#productInfo-productOption-fabric').on('change', function(){
			$('#productInfo-productOption-size').trigger('change');
			//Reorder them in correct order
			$(this).find('option').each(function(){
				var $prev = $(this).parent().find('option').eq($(this).data('order'));
				if($prev.length) $(this).insertAfter($prev);
				else $(this).parent().append($(this));
			});
		});*/
	} else if(product.static.id == 71){
		if(modelViewer) {
			modelViewer.onObjectLoad = function() {
				$('#productInfo-productOption-frosted-polymer-cover').on('change', function() {
					var cover = $(this).is(':checked');

					var checked = $('.image-thumb-list').find('.image-thumb[data-slug=texture]').hasClass('image-thumb-active');

					modelViewer.product.displayCover(cover, modelViewer);

					$('.image-thumb-list').find('.image-thumb:not([data-slug=texture])').each(function(){
						if($(this).attr('data-slug') == "texture") {
							return;
						}
						if(cover == ($(this).attr('data-slug').indexOf('coverless') !== -1)) {
							$(this).hide();
						} else {
							$(this).show();
							if(!checked) {
								$(this).click();
								checked = true;
							}
						}
					});
				});
				$('#productInfo-productOption-frosted-polymer-cover').trigger('change');
			};
		}
	} else if(product.static.id == 77){
		if(modelViewer) {
			modelViewer.onObjectLoad = function(){
				$('#productInfo-productOption-wrap-tie-color').off('change.3d');
				$('#productInfo-productOption-wrap-tie-color').on('change.3d', function() {
					modelViewer.product.setTieColor($(this).find(':selected').attr('value'), modelViewer);
				});
				$('#productInfo-productOption-wrap-tie-color').trigger('change.3d');
			};
		}
	} else if([83,84,85,88,93,96,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114,115,116].indexOf(product.static.id) !== -1){
		if(modelViewer) {
			modelViewer.onObjectLoad = function(){
				$('#productInfo-productOption-color').on("change", function(){
					product.handleColorOption(modelViewer);
					$('.image-thumb-list').find('.image-thumb').first().click();
				});
				$('#productInfo-productOption-color').trigger('change');
			};
		}
	} else if([99].indexOf(product.static.id) !== -1){
		if(modelViewer) {
			modelViewer.onObjectLoad = function(){
				$('#productInfo-productOption-loop-color').on("change", function(){
					product.handleLoopColorOption(modelViewer);
				});
				$('#productInfo-productOption-loop-color').trigger('change');
			};
		}
	} else if (product.static.id == 87) {
		if(modelViewer) {
			modelViewer.onTextureLoad = function() {
				modelViewer.object.children[0].material.map.rotation = -Math.PI/2;
				modelViewer.object.children[0].material.map.offset.x = 1;
			};
		}
	}

	// Subproduct Handling
	if(1+[27, 60, 64, 65, 86, 89, 90].indexOf(product.static.id)) {
		var selectname = 
			product.static.id == 27 ? '#productInfo-productOption-size' :
			product.static.id == 60 ? '#productInfo-productOption-size' :
			product.static.id == 86 ? '#productInfo-productOption-size' :
			product.static.id == 89 ? '#productInfo-productOption-size' :
			product.static.id == 90 ? '#productInfo-productOption-size' :
			product.static.id == 64 ? '#productInfo-productOption-product-style' : 
			product.static.id == 65 ? '#productInfo-productOption-product-style' : 
		'';
		
		$(selectname).on('change', function(){
			var value = $(this).find(':selected').attr('data-slug');
			$('.image-thumb-list').find('.image-thumb').each(function(){
				if($(this).attr('data-slug').split('-')[0] != value){
					$(this).hide();
				} else {
					$(this).show();
				}
			});
			if(window.aow.modelViewerContainer.finishedLoading.every(function(e){return e;})){
				window.aow.modelViewerContainer.display(product.static.slug + '-' + value);
			}
			$('.image-thumb-list').find('.image-thumb:visible').get(window.aow.selectedThumb)?.click();
		});
	}

	$('.image-thumb-list').find('.image-thumb:visible').get(window.aow.selectedThumb)?.click();

	// Maybe it's dead 
	if(!modelViewer) return;

	if([13, 14, 41, 38,87].indexOf(product.static.id) == -1){
		modelViewer.enableAutoRotate(1);
	}

	modelViewer.loadTexture($(this).attr('data-texture'));

	//Handle resize
	$(window).on('resize.modelViewer', function() {
		modelViewer.camera.updateProjectionMatrix();
		modelViewer.renderer.setSize($(container).width(), 500/560*$(container).width());
	});
};