//Object.create polyfill
if (typeof Object.create != 'function') {
  Object.create = (function() {
    var Temp = function() {};
    return function (prototype) {
      if (arguments.length > 1) {
        throw Error('Second argument not supported');
      }
      if(prototype !== Object(prototype) && prototype !== null) {
        throw TypeError('Argument must be an object or null');
     }
     if (prototype === null) {
        throw Error('null [[Prototype]] not supported');
      }
      Temp.prototype = prototype;
      var result = new Temp();
      Temp.prototype = null;
      return result;
    };
  })();
}

// Object.assign polyfill
if (typeof Object.assign != 'function') {
  // Must be writable: true, enumerable: false, configurable: true
  Object.defineProperty(Object, "assign", {
    value: function assign(target, varArgs) { // .length of function is 2
      'use strict';
      if (typeof target === 'undefined' || target === null) { // TypeError if undefined or null
        throw new TypeError('Cannot convert undefined or null to object');
      }

      var to = Object(target);

      for (var index = 1; index < arguments.length; index++) {
        var nextSource = arguments[index];

        if (typeof nextSource !== 'undefined' && nextSource !== null) { // Skip over if undefined or null
          for (var nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }
      return to;
    },
    writable: true,
    configurable: true
  });
}

if (!Array.prototype.copyWithin) {
  Array.prototype.copyWithin =
  // Array: Number[, Number[, Number]]
  function copyWithin(target, start, stop) {
    var positiveT = target >= 0,
        positiveS = (start = start | 0) >= 0,
        length    = this.length,
        zero      = 0,
        r         = function() {return ((+new Date()) * Math.random()).toString(36);},
        delimiter = "\b" + r() + "-" + r() + "-" + r() + "\b",
        hold;

    stop = stop || this.length;
    hold = this.slice.apply(this,
      positiveT?
        [start, stop]:
      positiveS?
        [start, -target]:
      [start])
    .join(delimiter);

    return this.splice.apply(this,
      positiveT?
        [target, stop - start, hold]:
      positiveS?
        [target, stop, hold]:
      [target, start, hold]),
            this.join(delimiter).split(delimiter).slice(zero, length);
  };
}

Function.prototype.inherits = function(parent) {
  this.prototype = Object.create(parent.prototype);
  this.prototype.parent = parent.prototype;
  this.prototype.static = this;
};


function isset() {
  var a = arguments,
	l = a.length,
	i = 0,
	undef;

  if (l === 0) {
	throw new Error('Empty isset');
  }

  while (i !== l) {
	if (a[i] === undef || a[i] === null) {
	  return false;
	}
	i++;
  }
  return true;
}
window.isset = isset;

$.fn.serializeObject = function()
{
	var o = {};
	var a = this.serializeArray();
	$.each(a, function() {
		if (o[this.name] !== undefined) {
			if (!o[this.name].push) {
				o[this.name] = [o[this.name]];
			}
			o[this.name].push(this.value || '');
		} else {
			o[this.name] = this.value || '';
		}
	});
	return o;
};

/**
 * Checks if an img is loaded. Works in most browsers except old IEs. Fallback returns true
 * @param  {Image}  img Image element to check
 * @return {Boolean} Returns TRUE if the image is loaded, FALSE if it's not
 */
function isImgLoaded(img) {
	if(!img.complete) return false;
	if(typeof img.naturalWidth !== 'undefined' && img.naturalWidth === 0) return false;
	return true;
}
window.isImgLoaded = isImgLoaded;

Number.prototype.toRad = function() {
	return this * Math.PI / 180;
};

/**
 * Map our own translator function to match the php equivalent
 */
function __(key, options) {
	return i18next.t(key, options);
}
window.__ = __;

function AOW() {
	this.ui_tasks = [];

	//Material dropdowns
	this.addUITask(function(selector) {
		$(selector).find('.dropdown-button').dropdown();
	});

	//Material scrollspy
	this.addUITask(function(selector) {
	    $(selector).find('.scrollspy').scrollSpy({'scrollOffset' : 0});
  	});

  	this.addUITask(function(selector) {
  		$(selector).find('.materialboxed').materialbox();
  		$(selector).find('.materialboxed-autowidth').materialbox_autowidth();
  	});

	//Form initialization
	this.addUITask(function(selector) {
		Materialize.updateTextFields();
		$(selector).find('select').not('.phpdebugbar-datasets-switcher').material_select();
		$(selector).find('textarea').trigger('autoresize');

		//Fix for input number validity considered as empty
		$(selector).find('input[type="number"]').each(function() {
			$(this).on('focus blur', function() {
				//Set value to 0 if string is invalid
				if($(this).val() === "")
					$(this).val(0);

				//Set label as active if value is empty or it is but it's not valid (meaning there's some invalid string in there)
				if($(this).val() !== "" || !this.validity.valid)  {
					$(this).siblings('label').addClass('active');
				}
			});
		});
	});

	//Fix for a.modal-action still triggering the href
	this.addUITask(function(selector) {
		$(selector).find('a.modal-action').on('click',function(e) {
			e.preventDefault();
			return false;
		});
	});

	//Tooltip init
	this.addUITask(function(selector) {
		$(selector).find('.tooltipped').tooltip();
	});

	//Submit triggers
	this.addUITask(function(selector) {
		$(selector).find('.trigger-submit').on('change', function() {
			$(this).closest('form').trigger('submit');
		});
	});

}
window.AOW = AOW;

AOW._devMode = 'dev';

AOW._requestErrorCodes = [404,500,501,502,503,504,505,506,507,508,510,511];
AOW.request = function(options) {
	options.url = 'ajax/'+options.url;

	//Make sure data is set
	if(!isset(options.data))
		options.data = {};

	//TODO: Add some kind of string/signed request or something

	//Add console error for DEV
	var errorFunc = options.error;
	options.error = function(response) {
		//Stop here and don't execute the error function if this is a 404 or 5XX error
		if(AOW._requestErrorCodes.indexOf(response.status) !== -1) {
			AOW.UI.toast('An error occured', 'error');
			return;
		}

		if(isset(errorFunc)) {
			errorFunc(response);
		} else {
			//Default to a toast
			if( typeof response.responseJSON !== 'undefined' &&
				typeof response.responseJSON.data !== 'undefined' && 
				typeof response.responseJSON.data.error !== 'undefined'
			) {
				AOW.UI.toast(response.responseJSON.data.error, 'error');
			} else {
				AOW.UI.toast('An error occured', 'error');
			}
		}

		AOW.DEV.c.error('AJAX '+options.method+' request error', response.responseText);
	};

	var onComplete = options.complete;
	options.complete = function(response, status) {
		if(typeof response.responseJSON !== 'undefined' && typeof response.responseJSON.flash !== 'undefined') {
			for(var key in response.responseJSON.flash) {
				AOW.UI.toast('', response.responseJSON.flash[key].type, response.responseJSON.flash[key].data);
			}
		}

		if(isset(onComplete)) onComplete(response, status);
	};

	var request = $.ajax(options);
	return request;
};

AOW.langRequest = function(options) {
	//Add lang prefix if there's one and it's not already there
	if(typeof LANG_PREFIX === 'string' && LANG_PREFIX !== "" && options.url.substr(0, LANG_PREFIX.length) !== LANG_PREFIX) {
		options.url = LANG_PREFIX+'/'+options.url;
	}

	return AOW.request(options);
};

AOW.parseUrlQuery = function(urlQuery) {
	var queryParams = {};
	// retreive the query porion of url and build a key-value object with it.
	queryParams = urlQuery
		.replace(/\?/, "")
		.split("&")
		.reduce(function(queryParams, stringKeyValuePair) {
		
			var keyValuePair = stringKeyValuePair.split("="),
			key = keyValuePair[0],
			value = keyValuePair[1];
			
			if( value && value.length > 0 ) {
				if( key.slice(-2) === "[]" ) {
					key = key.slice(0, -2);
					
					var oldValue = queryParams[key] || [];
					oldValue.push(value);
					
					value = oldValue;
				}
				
				queryParams[key] = value;
			}
			
			return queryParams;
		
		}, {});
	
	return queryParams;
};

AOW.buildUrlQuery = function(queryParams) {
	// builds a url query based on a key-value pairs object
    // value can be single, or an array
	var urlQuery = "";
	for( var key in queryParams ) {
		
		if( Array.isArray(queryParams[key]) ) {
			for( i=0; i<queryParams[key].length; i++ ) {
				
				if (( queryParams[key][i].toString()).length > 0 ) {
					
					if( urlQuery.length > 0 ) urlQuery += "&";
					urlQuery += ( key+"[]="+queryParams[key][i] );
				}
			}
		}
		else {
			
			if ( (queryParams[key].toString()).length > 0 ) {
				
				if( urlQuery.length > 0 ) urlQuery += "&";
				urlQuery += ( key+"="+queryParams[key] );
			}
		}
	}
	
	if( urlQuery.length > 0 ) urlQuery = "?"+urlQuery;
	
	return urlQuery;
};

AOW.UI = function() {};

AOW.UI._toastID = 0;
AOW.UI.toast = function(text, type, options) {
	if(!isset(type)) { type = 'notice'; }

	var manual_close = function(id) {
		$('#'+id).closest('.toast').remove();
	};

	var toast_html;

	switch(type) {
		case 'notice':
			Materialize.toast('<span>'+text+'</span>', 3000);
			break;

		case 'error':
			Materialize.toast('<span>'+text+'</span>', 4000);
			break;

		case 'action':
			if(!isset(options) || !isset(options.action)) {
				throw new Error('No action specified for the Action Toast');
			}

			if(!isset(options) || !isset(options.action_text)) {
				throw new Error('No action text specified for the Action Toast');
			}

			//Base html
			toast_html = '<div><span>'+text+'</span><a class="btn-flat secondary-color" id="toast-'+AOW.UI._toastID+'-action" href="#!">'+options.action_text+'<a>';

			//Check if there's an image specified (then it's a 'rich' toast)
			if(isset(options.image)) {
				toast_html = '<div class="rich-toast">'+
								'<img src="'+options.image+'"/>'+
								'<div class="toast-content">'+
									'<span>'+text+'</span>';

				//Don't add button if there's no actionText
				if(options.action_text !== "")
					toast_html +='<a class="btn-flat secondary-color" id="toast-'+AOW.UI._toastID+'-action" href="#!">'+options.action_text+'<a>';

				toast_html += '</div>'+
							'</div>';

			}

			toast_html += '</div>';

			Materialize.toast(toast_html, 5000);

			$('#toast-'+AOW.UI._toastID+'-action').on('click', function(e) {
				e.preventDefault();

				//Call the action
				options.action();

				var toastElem = $(this).closest('.toast');

				// Animate toast out (taken directly from Materialize, until they add a way to manually close toasts)
				Vel(toastElem, {"opacity": 0, marginTop: '-40px'}, { duration: 375,
					easing: 'easeOutExpo',
					queue: false,
					complete: function(){
						// Remove toast after it times out
						$(this).remove();
					}
				});
			});
			break;

		case 'goal':
			toast_html = "";

			var currentId = AOW.UI._toastID;

			// Different design if the goal is completed
			if(options.count >= options.total) {
				toast_html = '<div class="card horizontal goal-toast complete" id="toast-'+currentId+'">'+
							'<div class="card-image">'+
								'<img src="'+IMG_URL+options.image+'">'+
							'</div>'+
							'<div class="card-stacked">'+
								'<div class="card-content">'+
									'<h4>'+__('account_ns_toast.goal_completed')+'</h4>'+
									'<p>' +__('account_ns_toast.goal_unlocked') + ' ' + options.reward + '</p>'+
									'<button class="toast-close"><i class="material-icons">close</i></button>'+
								'</div>'+
							'</div>'+
						'</div>';
			} else {
				var percent = (options.count / options.total) * 100;

				toast_html = '<div class="card horizontal goal-toast" id="toast-'+currentId+'">'+
								'<div class="card-image">'+
									'<img src="'+IMG_URL+options.image+'">'+
								'</div>'+
								'<div class="card-stacked">'+
									'<div class="card-content">'+
										'<h4>'+__('account_ns_toast.goal_label')+': '+options.name+'</h4>'+
										'<button class="toast-close"><i class="material-icons">close</i></button>'+
									'</div>'+
									'<div class="card-action">'+
										'<span class="counter">'+options.count+' / '+options.total+'</span>'+
										'<div class="progress green lighten-4">'+
											'<div class="determinate green" style="width: '+percent+'%"></div>'+
										'</div>'+
									'</div>'+
								'</div>'+
							'</div>';
			}

			Materialize.toast(toast_html, 15000);

			$('#toast-'+currentId).find('.toast-close').on('click', function() {
				$(this).closest('.toast').remove();
			});

			break;
	}

	AOW.UI._toastID++;
};

AOW.UI._dialogID = 0;
AOW.UI.dialog = function(options) {
	var id = AOW.UI._dialogID;

	//Add lang prefix if there's one and it's not already there
	if(typeof LANG_PREFIX === 'string' && LANG_PREFIX !== "" && options.url.substr(0, LANG_PREFIX.length) !== LANG_PREFIX) {
		options.url = LANG_PREFIX+'/'+options.url;
	}

	//Add the 'dialog' handle
	options.url = 'dialog/'+options.url;

	//Override success function to call the dialog box before
	var successFunc = options.success;
	var completeFunc = options.close;
	options.success = function(response) {
		//Create the modal
		$(response.data.html).appendTo('body').attr('id','modal-'+id);

		if(options.dark)
			$('#modal-'+id).addClass('dark-mode');	

		//Open the modal
		$('#modal-'+id).modal({
			dismissible: typeof options.dismissible != "undefined" ? options.dismissible : true,
			ready: function() {
				aow.initUI('#modal-'+id);
			},
			complete: function() {
				//Remove the modal when we dismiss it, and trigger a custom event if we need to be aware of it
				$('#modal-'+id).trigger('dialog.close').remove();

				if(isset(completeFunc)) { completeFunc.call($('#modal-'+id), response); }
			}
		}).modal('open');

		//Call the provided success function
		if(isset(successFunc)) { successFunc.call($('#modal-'+id), response); }
	};

	AOW.request(options);

	AOW.UI._dialogID++;

	return id;
};

AOW.UI.dialog_close = function(id) {
	$('#modal-'+id).modal('close');
};

(function($) {
	$.fn.extend({
		//Closes the dialog and destroy it
		closeDialog: function() {
			var me = this;
			$(this).modal('close');
		}
	});
})(jQuery);


AOW.UI.addTutorialVideo = function(url){
    if($('#TutorialVideo').length) {
        $('#TutorialVideo').find('iframe').attr('src', url);
        $('#TutorialVideo').modal({
        	complete: function() {
        		$('#TutorialVideo').remove();
        	}
        }).modal('open');
        return false;
    }

    var video = '<div id="TutorialVideo" class="modal">' +
        '<div class="modal-content">' +
            '<div class="video-container">' +
                '<iframe width="1280" height="720" src="' + url + '" allowfullscreen></iframe>' +
            '</div>' +
        '</div>' +
    '</div>';

    $('#main').append(video);
    $('#TutorialVideo').modal({
    	complete: function() {
    		$('#TutorialVideo').remove();
    	}
    }).modal('open');
    return true;
};

AOW.UI.addLoader = function(elem, color, size) {
	if(!isset(color)) {
        color = 'blue';
	}

	if(!isset(size)) {
		size = 'small';
	}

	$(elem).append('<div class="preloader-wrapper '+size+' active">'+
					'<div class="spinner-layer spinner-'+color+'-only">'+
					  '<div class="circle-clipper left">'+
						'<div class="circle"></div>'+
					  '</div><div class="gap-patch">'+
						'<div class="circle"></div>'+
					  '</div><div class="circle-clipper right">'+
						'<div class="circle"></div>'+
					  '</div>'+
					'</div>'+
				  '</div>');
};

AOW.UI.removeLoader = function(elem) {
	$(elem).find('.preloader-wrapper').remove();
};

/**
 * SimpleBar management methods
 */

AOW.UI.addSimpleBar = function(element) {
	if(!AOW.UI.simplebars)
		AOW.UI.simplebars = [];

	element.SimpleBar = new SimpleBar(element);

	element.SimpleBar.mutationObserver.disconnect();
    element.SimpleBar.resizeObserver.disconnect();

    $(element.SimpleBar.getScrollElement()).on('scroll touchmove', function(e){
    	if(this.scrollHeight > this.clientHeight)
			e.stopPropagation();
		else 
			e.preventDefault();
    });

	AOW.UI.simplebars.push(element);
};

AOW.UI.removeSimpleBar = function(element) {
	if(!AOW.UI.simplebars) return;

	element.SimpleBar.unMount();
	AOW.UI.simplebars.splice(AOW.UI.simplebars.indexOf(element), 1);
};

AOW.UI.updateSimpleBar = function(element) {
	if(!AOW.UI.simplebars) return;

	if(element){
		element.SimpleBar.recalculate();
	} else {
		for(var i = 0; i < AOW.UI.simplebars.length; i++){
			AOW.UI.simplebars[i].SimpleBar.recalculate();
		}
	}
};

/**
 * Copy of MaterializeCSS fadeInImage method, with added duration
 * @param  mixed selector Object or string to use as a jQuery selector
 * @param  Number duration Duration in ms
 */
AOW.UI.fadeIn =  function(selector, duration){
	if(!isset(duration))
		duration = 1300;

	var element = $(selector);
	element.css({opacity: 0});
	$(element).velocity({opacity: 1}, {
		duration: duration/2,
		queue: false,
		easing: 'easeOutSine'
	  });
	$(element).velocity({opacity: 1}, {
		  duration: duration,
		  queue: false,
		  easing: 'swing',
		  progress: function(elems, complete) {
			now = 100 - complete*100;
			  var grayscale_setting = now;
			  var brightness_setting = 150 - (100 - now)/1.75;

			  if (brightness_setting < 100) {
				brightness_setting = 100;
			  }
			  if (now >= 0) {
				$(this).css({
					"-webkit-filter": "grayscale("+grayscale_setting+"%) brightness("+brightness_setting+"%)",
					"filter": "grayscale("+grayscale_setting+"%) brightness("+brightness_setting+"%)"
				});
			  }
		  }
	  });
  };

/**
 * Set a transparent grey overlay on top of whole page
 * @param message (string) content to be displayed on top of overlay
 */
AOW.UI.setPageOverlay = function(content, callback) {
	me_ui = this;
	$overlay = $('body').prepend(
		'<div id="pageOverlay">'+
			'<div id="closeOverlay"><i class="material-icons">clear</i></div>'+
			'<div id="overlayContainer" class="row">'+
				'<div id="overlayItem" class="col s12 m8 offset-m2 l4 offset-l4">'+(content || '')+'</div>'+
			'</div>'+
		'</div>'
	);
	$overlay.find('#closeOverlay').on('click', function() {
		me_ui.unsetPageOverlay(callback);
	});
	return $overlay;
};

AOW.UI.unsetPageOverlay = function(callback) {
	$overlay = $('body').find('#pageOverlay');

	if($overlay.length) {
		$.when( $('body').find('#pageOverlay').remove() )
		.then( function() {
			if (typeof callback !== 'undefined') {
				callback();
			}
		});
	}
};

AOW.UI.updatePageOverlayText = function(content) {
	$('#pageOverlay').find('#overlayContent').html(content);
};

/**
 * Executes added UI tasks on the specified selector
 * @param  mixed selector Any jQuery valid selector (Default: body)
 */
AOW.prototype.initUI = function(selector) {
	//Default selector to whole body
	if(!isset(selector)) {
		selector = 'body';
	}

	for (var i = 0; i < this.ui_tasks.length; i++) {
		this.ui_tasks[i](selector);
	}
};

/**
 * Adds a UI task
 * @param function func The UI task to execute
 */
AOW.prototype.addUITask = function(func) {
	this.ui_tasks.push(func);
};

//Developement tools
AOW.DEV = function() {};
if(AOW._devMode == 'dev') {
	AOW.DEV.c = console;
}


(function ($) {

  $.fn.materialbox_autowidth = function () {

	return this.each(function() {

	  if ($(this).hasClass('initialized')) {
		return;
	  }

	  $(this).addClass('initialized');

	  var overlayActive = false;
	  var doneAnimating = true;
	  var inDuration = 275;
	  var outDuration = 200;
	  var origin = $(this);
	  var placeholder = $('<div></div>').addClass('material-placeholder');
	  var originalWidth = 0;
	  var originalHeight = 0;
	  var ancestorsChanged;
	  var ancestor;
	  origin.wrap(placeholder);


	  origin.on('click', function(){
		var placeholder = origin.parent('.material-placeholder');
		var windowWidth = window.innerWidth;
		var windowHeight = window.innerHeight;
		var originalWidth = origin.width();
		var originalHeight = origin.height();


		// If already modal, return to original
		if (doneAnimating === false) {
		  returnToOriginal();
		  return false;
		}
		else if (overlayActive && doneAnimating===true) {
		  returnToOriginal();
		  return false;
		}

		// Set positioning for placeholder
		placeholder.css({
		  width: placeholder[0].getBoundingClientRect().width,
		  height: placeholder[0].getBoundingClientRect().height,
		  position: 'relative',
		  top: 0,
		  left: 0
		});


		// Set states
		doneAnimating = false;
		origin.addClass('active');
		overlayActive = true;

		// Find ancestor with overflow: hidden; and remove it
		ancestorsChanged = undefined;
		ancestor = placeholder[0].parentNode;
		var count = 0;
		while (ancestor !== null && !$(ancestor).is(document)) {
		  var curr = $(ancestor);
		  if (curr.css('overflow') === 'hidden') {
			curr.css('overflow', 'visible');
			if (ancestorsChanged === undefined) {
			  ancestorsChanged = curr;
			}
			else {
			  ancestorsChanged = ancestorsChanged.add(curr);
			}
		  }
		  ancestor = ancestor.parentNode;
		}

		// Set css on origin
		origin.css({position: 'absolute', 'z-index': 1000})
		.data('width', originalWidth)
		.data('height', originalHeight);

		// Add overlay
		var overlay = $('<div id="materialbox-overlay"></div>')
		  .css({
			opacity: 0
		  })
		  .click(function(){
			if (doneAnimating === true)
			returnToOriginal();
		  });
		  // Animate Overlay
		  $('body').append(overlay);
		  overlay.velocity({opacity: 1}, {duration: inDuration, queue: false, easing: 'easeOutQuad'}
			);


		// Add and animate caption if it exists
		if (origin.data('caption') !== "") {
		  var $photo_caption = $('<div class="materialbox-caption"></div>');
		  $photo_caption.text(origin.data('caption'));
		  $('body').append($photo_caption);
		  $photo_caption.css({ "display": "inline" });
		  $photo_caption.velocity({opacity: 1}, {duration: inDuration, queue: false, easing: 'easeOutQuad'});
		}



		// Resize Image
		var ratio = 0;
		var widthPercent = originalWidth / windowWidth;
		var heightPercent = originalHeight / windowHeight;
		var newWidth = 0;
		var newHeight = 0;

		var scaleX = Math.min(0.9, origin.get(0).naturalWidth / windowWidth);
		var scaleY = Math.min(0.9, origin.get(0).naturalHeight / windowHeight);

		if (widthPercent > heightPercent) {
		  ratio = originalHeight / originalWidth;
		  newWidth = windowWidth * scaleX;
		  newHeight = windowWidth * scaleX * ratio;
		}
		else {
		  ratio = originalWidth / originalHeight;
		  newWidth = (windowHeight * scaleY) * ratio;
		  newHeight = windowHeight * scaleY;
		}

		// Animate image + set z-index
		if(origin.hasClass('responsive-img')) {
		  origin.velocity({'max-width': newWidth, 'width': originalWidth}, {duration: 0, queue: false,
			complete: function(){
			  origin.css({left: 0, top: 0})
			  .velocity(
				{
				  height: newHeight,
				  width: newWidth,
				  left: $(document).scrollLeft() + windowWidth/2 - origin.parent('.material-placeholder').offset().left - newWidth/2,
				  top: $(document).scrollTop() + windowHeight/2 - origin.parent('.material-placeholder').offset().top - newHeight/ 2
				},
				{
				  duration: inDuration,
				  queue: false,
				  easing: 'easeOutQuad',
				  complete: function(){doneAnimating = true;}
				}
			  );
			} // End Complete
		  }); // End Velocity
		}
		else {
		  origin.css('left', 0)
		  .css('top', 0)
		  .velocity(
			{
			  height: newHeight,
			  width: newWidth,
			  left: $(document).scrollLeft() + windowWidth/2 - origin.parent('.material-placeholder').offset().left - newWidth/2,
			  top: $(document).scrollTop() + windowHeight/2 - origin.parent('.material-placeholder').offset().top - newHeight/ 2
			},
			{
			  duration: inDuration,
			  queue: false,
			  easing: 'easeOutQuad',
			  complete: function(){doneAnimating = true;}
			}
			); // End Velocity
		}

	}); // End origin on click


	  // Return on scroll
	  $(window).scroll(function() {
		if (overlayActive ) {
		  returnToOriginal();
		}
	  });

	  // Return on ESC
	  $(document).keyup(function(e) {

		if (e.keyCode === 27 && doneAnimating === true) {   // ESC key
		  if (overlayActive) {
			returnToOriginal();
		  }
		}
	  });


	  // This function returns the modaled image to the original spot
	  function returnToOriginal() {

		  doneAnimating = false;

		  var placeholder = origin.parent('.material-placeholder');
		  var windowWidth = window.innerWidth;
		  var windowHeight = window.innerHeight;
		  var originalWidth = origin.data('width');
		  var originalHeight = origin.data('height');

		  origin.velocity("stop", true);
		  $('#materialbox-overlay').velocity("stop", true);
		  $('.materialbox-caption').velocity("stop", true);


		  $('#materialbox-overlay').velocity({opacity: 0}, {
			duration: outDuration, // Delay prevents animation overlapping
			queue: false, easing: 'easeOutQuad',
			complete: function(){
			  // Remove Overlay
			  overlayActive = false;
			  $(this).remove();
			}
		  });

		  // Resize Image
		  origin.velocity(
			{
			  width: originalWidth,
			  height: originalHeight,
			  left: 0,
			  top: 0
			},
			{
			  duration: outDuration,
			  queue: false, easing: 'easeOutQuad'
			}
		  );

		  // Remove Caption + reset css settings on image
		  $('.materialbox-caption').velocity({opacity: 0}, {
			duration: outDuration, // Delay prevents animation overlapping
			queue: false, easing: 'easeOutQuad',
			complete: function(){
			  placeholder.css({
				height: '',
				width: '',
				position: '',
				top: '',
				left: ''
			  });

			  origin.css({
				height: '',
				top: '',
				left: '',
				width: '',
				'max-width': '',
				position: '',
				'z-index': ''
			  });

			  // Remove class
			  origin.removeClass('active');
			  doneAnimating = true;
			  $(this).remove();

			  // Remove overflow overrides on ancestors
			  // Well maybe there's none to be honest
			  if(typeof ancestorsChanged !== 'undefined'){
			  	ancestorsChanged.css('overflow', '');
			  }
			}
		  });

		}
		});
};

}( jQuery ));

(function ($) {

  var methods = {
    init : function(options) {
      var defaults = {
        menuWidth: 300,
        edge: 'left',
        closeOnClick: false,
        draggable: true,
        onOpen: null,
        onClose: null
      };
      options = $.extend(defaults, options);

      $(this).each(function(){
        var $this = $(this);
        var menuId = $this.attr('data-activates');
        var menu = $("#"+ menuId);

        // Set to width
        if (options.menuWidth != 300) {
          menu.css('width', options.menuWidth);
        }

        // Add Touch Area
        var $dragTarget = $('.drag-target[data-sidenav="' + menuId + '"]');
        if (options.draggable) {
          // Regenerate dragTarget
          if ($dragTarget.length) {
            $dragTarget.remove();
          }

          $dragTarget = $('<div class="drag-target"></div>').attr('data-sidenav', menuId);
          $('body').append($dragTarget);
        } else {
          $dragTarget = $();
        }

        if (options.edge == 'left') {
          menu.css('transform', 'translateX(-100%)');
          $dragTarget.css({'left': 0}); // Add Touch Area
        }
        else {
          menu.addClass('right-aligned') // Change text-alignment to right
            .css('transform', 'translateX(100%)');
          $dragTarget.css({'right': 0}); // Add Touch Area
        }

        // If fixed sidenav, bring menu out
        if (menu.hasClass('fixed')) {
            if (window.innerWidth > 992) {
              menu.css('transform', 'translateX(0)');
            }
          }

        // Window resize to reset on large screens fixed
        if (menu.hasClass('fixed')) {
          $(window).resize( function() {
            if (window.innerWidth > 992) {
              // Close menu if window is resized bigger than 992 and user has fixed sidenav
              if ($('#sidenav-overlay').length !== 0 && menuOut) {
                removeMenu(true);
              }
              else {
                // menu.removeAttr('style');
                menu.css('transform', 'translateX(0%)');
                // menu.css('width', options.menuWidth);
              }
            }
            else if (menuOut === false){
              if (options.edge === 'left') {
                menu.css('transform', 'translateX(-100%)');
              } else {
                menu.css('transform', 'translateX(100%)');
              }

            }

          });
        }

        // if closeOnClick, then add close event for all a tags in side sideNav
        if (options.closeOnClick === true) {
          menu.on("click.itemclick", "a:not(.collapsible-header)", function(){
            removeMenu();
          });
        }

        var removeMenu = function(restoreNav) {
          panning = false;
          menuOut = false;
          // Reenable scrolling
          $('body').css({
            overflow: '',
            width: ''
          });

          $('#sidenav-overlay').velocity({opacity: 0}, {duration: 200,
              queue: false, easing: 'easeOutQuad',
            complete: function() {
              $(this).remove();
            } });
          if (options.edge === 'left') {
            // Reset phantom div
            $dragTarget.css({width: '', right: '', left: '0'});
            menu.velocity(
              {'translateX': '-100%'},
              { duration: 200,
                queue: false,
                easing: 'easeOutCubic',
                complete: function() {
                  if (restoreNav === true) {
                    // Restore Fixed sidenav
                    menu.removeAttr('style');
                    menu.css('width', options.menuWidth);
                  }
                }

            });
          }
          else {
            // Reset phantom div
            $dragTarget.css({width: '', right: '0', left: ''});
            menu.velocity(
              {'translateX': '100%'},
              { duration: 200,
                queue: false,
                easing: 'easeOutCubic',
                complete: function() {
                  if (restoreNav === true) {
                    // Restore Fixed sidenav
                    menu.removeAttr('style');
                    menu.css('width', options.menuWidth);
                  }
                }
              });
          }

          // Call callback
          if(options.onClose) options.onClose();
        };

        // Touch Event
        var panning = false;
        var menuOut = false;

        if (options.draggable) {
          $dragTarget.on('click', function(){
            if (menuOut) {
              removeMenu();
            }
          });

          $dragTarget.hammer({
            prevent_default: false
          }).bind('pan', function(e) {

            if (e.gesture.pointerType == "touch") {

              var direction = e.gesture.direction;
              var x = e.gesture.center.x;
              var y = e.gesture.center.y;
              var velocityX = e.gesture.velocityX;

              // Disable Scrolling
              var $body = $('body');
              var $overlay = $('#sidenav-overlay');
              var oldWidth = $body.innerWidth();
              $body.css('overflow', 'hidden');
              $body.width(oldWidth);

              // If overlay does not exist, create one and if it is clicked, close menu
              if ($overlay.length === 0) {
                $overlay = $('<div id="sidenav-overlay"></div>');
                $overlay.css('opacity', 0).click( function(){
                  removeMenu();
                });
                $('body').append($overlay);
              }

              // Keep within boundaries
              if (options.edge === 'left') {
                if (x > options.menuWidth) { x = options.menuWidth; }
                else if (x < 0) { x = 0; }
              }

              if (options.edge === 'left') {
                // Left Direction
                if (x < (options.menuWidth / 2)) { menuOut = false; }
                // Right Direction
                else if (x >= (options.menuWidth / 2)) { menuOut = true; }
                menu.css('transform', 'translateX(' + (x - options.menuWidth) + 'px)');
              }
              else {
                // Left Direction
                if (x < (window.innerWidth - options.menuWidth / 2)) {
                  menuOut = true;
                }
                // Right Direction
                else if (x >= (window.innerWidth - options.menuWidth / 2)) {
                 menuOut = false;
               }
                var rightPos = (x - options.menuWidth / 2);
                if (rightPos < 0) {
                  rightPos = 0;
                }

                menu.css('transform', 'translateX(' + rightPos + 'px)');
              }


              // Percentage overlay
              var overlayPerc;
              if (options.edge === 'left') {
                overlayPerc = x / options.menuWidth;
                $overlay.velocity({opacity: overlayPerc }, {duration: 10, queue: false, easing: 'easeOutQuad'});
              }
              else {
                overlayPerc = Math.abs((x - window.innerWidth) / options.menuWidth);
                $overlay.velocity({opacity: overlayPerc }, {duration: 10, queue: false, easing: 'easeOutQuad'});
              }
            }

          }).bind('panend', function(e) {

            if (e.gesture.pointerType == "touch") {
              var $overlay = $('#sidenav-overlay');
              var velocityX = e.gesture.velocityX;
              var x = e.gesture.center.x;
              var leftPos = x - options.menuWidth;
              var rightPos = x - options.menuWidth / 2;
              if (leftPos > 0 ) {
                leftPos = 0;
              }
              if (rightPos < 0) {
                rightPos = 0;
              }
              panning = false;

              if (options.edge === 'left') {
                // If velocityX <= 0.3 then the user is flinging the menu closed so ignore menuOut
                if ((menuOut && velocityX <= 0.3) || velocityX < -0.5) {
                  // Return menu to open
                  if (leftPos !== 0) {
                    menu.velocity({'translateX': [0, leftPos]}, {duration: 300, queue: false, easing: 'easeOutQuad'});
                  }

                  $overlay.velocity({opacity: 1 }, {duration: 50, queue: false, easing: 'easeOutQuad'});
                  $dragTarget.css({width: '50%', right: 0, left: ''});
                  menuOut = true;
                }
                else if (!menuOut || velocityX > 0.3) {
                  // Enable Scrolling
                  $('body').css({
                    overflow: '',
                    width: ''
                  });
                  // Slide menu closed
                  menu.velocity({'translateX': [-1 * options.menuWidth - 10, leftPos]}, {duration: 200, queue: false, easing: 'easeOutQuad'});
                  $overlay.velocity({opacity: 0 }, {duration: 200, queue: false, easing: 'easeOutQuad',
                    complete: function () {
                      $(this).remove();
                    }});
                  $dragTarget.css({width: '10px', right: '', left: 0});
                }
              }
              else {
                if ((menuOut && velocityX >= -0.3) || velocityX > 0.5) {
                  // Return menu to open
                  if (rightPos !== 0) {
                    menu.velocity({'translateX': [0, rightPos]}, {duration: 300, queue: false, easing: 'easeOutQuad'});
                  }

                  $overlay.velocity({opacity: 1 }, {duration: 50, queue: false, easing: 'easeOutQuad'});
                  $dragTarget.css({width: '50%', right: '', left: 0});
                  menuOut = true;
                }
                else if (!menuOut || velocityX < -0.3) {
                  // Enable Scrolling
                  $('body').css({
                    overflow: '',
                    width: ''
                  });

                  // Slide menu closed
                  menu.velocity({'translateX': [options.menuWidth + 10, rightPos]}, {duration: 200, queue: false, easing: 'easeOutQuad'});
                  $overlay.velocity({opacity: 0 }, {duration: 200, queue: false, easing: 'easeOutQuad',
                    complete: function () {
                      $(this).remove();
                    }});
                  $dragTarget.css({width: '10px', right: 0, left: ''});
                }
              }

            }
          });
        }

        $this.off('click.sidenav').on('click.sidenav', function() {
          if (menuOut === true) {
            menuOut = false;
            panning = false;
            removeMenu();
          }
          else {
            // Disable Scrolling
            var $body = $('body');
            var $overlay = $('<div id="sidenav-overlay"></div>');
            var oldWidth = $body.innerWidth();
            $body.css('overflow', 'hidden');
            $body.width(oldWidth);

            // Push current drag target on top of DOM tree
            $('body').append($dragTarget);

            if (options.edge === 'left') {
              $dragTarget.css({width: '50%', right: 0, left: ''});
              menu.velocity({'translateX': [0, -1 * options.menuWidth]}, {duration: 300, queue: false, easing: 'easeOutQuad'});
            }
            else {
              $dragTarget.css({width: '50%', right: '', left: 0});
              menu.velocity({'translateX': [0, options.menuWidth]}, {duration: 300, queue: false, easing: 'easeOutQuad'});
            }

            $overlay.css('opacity', 0)
            .click(function(){
              menuOut = false;
              panning = false;
              removeMenu();
              $overlay.velocity({opacity: 0}, {duration: 300, queue: false, easing: 'easeOutQuad',
                complete: function() {
                  $(this).remove();
                } });

            });
            $('body').append($overlay);
            $overlay.velocity({opacity: 1}, {duration: 300, queue: false, easing: 'easeOutQuad',
              complete: function () {
                menuOut = true;
                panning = false;
              }
            });

            // Call Callback
            if(options.onOpen) options.onOpen();
          }

          return false;
        });
      });
    },
    destroy: function () {
      var $overlay = $('#sidenav-overlay');
      var $dragTarget = $('.drag-target[data-sidenav="' + $(this).attr('data-activates') + '"]');
      $overlay.trigger('click');
      $dragTarget.remove();
      $(this).off('click');
      $overlay.remove();
    },
    show : function() {
      this.trigger('click');
    },
    hide : function() {
      $('#sidenav-overlay').trigger('click');
    }
  };

  $.fn.sideNav = function(methodOrOptions) {
    if ( methods[methodOrOptions] ) {
      return methods[ methodOrOptions ].apply( this, Array.prototype.slice.call( arguments, 1 ));
    } else if ( typeof methodOrOptions === 'object' || ! methodOrOptions ) {
      // Default to "init"
      return methods.init.apply( this, arguments );
    } else {
      $.error( 'Method ' +  methodOrOptions + ' does not exist on jQuery.sideNav' );
    }
  }; // Plugin end
}( jQuery ));

